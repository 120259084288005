.sidebar{
  &[data-color="black"]:after{
      @include icon-gradient(#9F2241, rgba(#9F2241,0.6));
  }
    .logo-img img {
      max-width: 230px;
      //max-height: 40px;
    }
}

@media (max-width: 991px){
  .sidebar .nav > li.active.active-pro {
    position: relative;
    bottom: unset;
  }
}

#bodyClick {
  position: absolute;
}
